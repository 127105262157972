<script setup>
import { ref, computed, onMounted, provide } from 'vue';
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import _ from 'lodash'
import SubscriptionService from '@/services/SubscriptionService'
import subscribePlansDescription from '@/components/Subscribe/subscribePlansDescription'
import SubscribePlans from '@/components/Subscribe/SubscribePlans.vue';
import SubscribePlansCompare from '@/components/Subscribe/SubscribePlansCompare.vue';
import SubscribePlanCart from '@/components/Subscribe/SubscribePlanCart.vue';
import Spinner from '../components/ui/Spinner.vue';
import ProductSwitcher from '../components/ui/ProductSwitcher.vue'

const store = useStore()
const route = useRoute()
const router = useRouter()

const yearly = ref(true)

const packCategories = ref([
  { label: 'all in one', value: 'live' },
  { label: 'multistream', value: 'restream' },
])
const packCategory = ref({ label: 'all in one', value: 'live' })
const AvailablePaymentMethods = [{ label: 'Fastspring', value: 'fastspring-contextual' }]

const subscriptionPackages = ref([])
const subscriptionPackage = ref(null)
const paymentMethodsVisible = ref(true)
const hiddenPack = ref(false)
const error = ref(null)
const loading = ref(false)
const productType = ref('streaming')

const availablePaymentMethods = ref(AvailablePaymentMethods)
const selectedPaymentMethod = ref(availablePaymentMethods.value[0])

const updateSelectedPaymentMethod = (method) => {
  selectedPaymentMethod.value = method
}

const selectPackage = (pack) => {
  subscriptionPackage.value = pack
  router.push({ query: { package: pack?._id } })
}

const selectPaymentMethod = (paymentMethod) => {
  selectedPaymentMethod.value = paymentMethod
}
provide('selectedPaymentMethod', {
  selectedPaymentMethod,
  selectPaymentMethod,
  selectPackage,
  availablePaymentMethods,
  updateSelectedPaymentMethod
})

const baseSubscription = computed(() => store.getters['User/baseSubscription'])

const loadPackages = async () => {
  store.dispatch('Ui/setLoadingStatus', true);
  try {
    // Fetch available subscriptions packages
    let packages = await SubscriptionService.getSubscriptionPackages()
    packages = _.sortBy(packages, p => {
      p?.name[0]
    })

    subscriptionPackages.value = packages
    const paymentMethods = await SubscriptionService.getUserPaymentMethods()
    selectedPaymentMethod.value = paymentMethods[0]
    availablePaymentMethods.value = paymentMethods

    if (!_.isEmpty(paymentMethods) && paymentMethods.length === 1) {
      paymentMethodsVisible.value = false
    }

    // Set selected subscription
    let packageId = route.query?.package
    const showPromotionPackageOnly = route.query?.ppo

    if (packageId && !hiddenPack.value) {
      subscriptionPackage.value = _.find(plansData.value, { _id: packageId })
      if (_.isEmpty(subscriptionPackage.value) || !subscriptionPackage.value) {
        subscriptionPackage.value = await SubscriptionService.getSubscriptionPackages(packageId);
        hiddenPack.value = true;
      }

      // Update plan category
      setTimeout(() => {
        const packCat = _.find(packCategories.value, { value: subscriptionPackage.value?.category })
        if (packCat) { packCategory.value = packCat }
        else { packCategory.value = packCategories.value[0] }
        if (packCat) {
          this.selectSubscriptionPackageCategory(packCat, false)
        }
        if (showPromotionPackageOnly === 'true') {
          packages.value = [subscriptionPackage.value]
          packCategories.value = [packCat]
        }
      }, 100)
    } else {
      // Read url query params
      this.processURLSearchParams()
    }

    this.updateUserBaseSubscription()
    let baseSub = baseSubscription.value

    const action = route.query?.action
    const baseCharge = parseFloat(route.query?.bycharge)

    if (action === 'upgrade' || (subscriptionPackage.value?.baseCharge === 0)) {
      const userPackage = baseSub ? baseSub.package : { baseCharge: 0, cycleAge: 30 }
      let superiorPackage = _.find(packages.value, p => {
        let minBaseCharge = Math.max(userPackage.baseCharge, 9.99)
        let minCycleAge = Math.max(userPackage.cycleAge, 30)
        return p.cycleAge === minCycleAge && p.baseCharge > minBaseCharge
      })

      if (!superiorPackage) { superiorPackage = _.find(packages.value, p => p.baseCharge === userPackage.baseCharge) }
      if (superiorPackage) {
        subscriptionPackage.value = superiorPackage
      }
    } else if (action === 'resubscribe') {
      const userPackage = baseSub ? baseSub.package : { baseCharge: 0 }
      const curPack = _.find(packages.value, p => p.baseCharge === userPackage.baseCharge)
      subscriptionPackage.value = curPack
    } else if (baseCharge) {
      const isAnnualPack = route.query?.annual === '1'

      const subPack = _.find(packages.value, p => {
        let bool = p.baseCharge === baseCharge
        if (isAnnualPack) {
          bool = bool && /year|annual/gi.test(p.name)
        }

        return bool
      })
      if (subPack) {
        subscriptionPackage.value = subPack
      }
    }
    store.dispatch('Ui/setLoadingStatus', false);
  } catch (e) {
    error.value = e
  }
}

const serverPackages = computed(() => {
  if (yearly.value && productType.value === 'streaming') {
    return subscriptionPackages.value.filter(pack => ((pack.cycleAge === 365) && (pack.category !== 'ott')))
  } else if (yearly.value && productType.value === 'ott') {
    return subscriptionPackages.value.filter(pack => ((pack.cycleAge === 365) && (pack.category === 'ott')))
  } else if (productType.value === 'ott') {
    return subscriptionPackages.value.filter(pack => ((pack.cycleAge === 30) && (pack.category === 'ott')))
  } else {
    return subscriptionPackages.value.filter(pack => ((pack.cycleAge === 30) && (pack.category !== 'ott')))
  }
})

const normalizeOttNames = (info) => {
  if(info?.category === 'ott' && info?.name) {
    return {
      ...info,
      name: info.name.replace('OTT - ', '').replace(' - Yearly', '')
    }
  }
  return info;
}

const normalizeInfoOttNames = (info) => {
  if(info?.name?.includes('OTT')) {
    return {
      ...info,
      name: info.name.replace('OTT - ', '').replace(' - Yearly', '')
    }
  }
  return info;
}

const plansData = computed(() => {
  return serverPackages.value.map(pack => ({
    ...normalizeOttNames(pack),
    charectiristics: subscribePlansDescription.find(plan => {
      if (yearly.value && productType.value === 'streaming') {
        return (plan.info.name + ' - Yearly' === pack.name) || (plan.info.name + ' Yearly' === pack.name)
      } else if (productType.value === 'ott' && !yearly.value) {
        return plan.info.name === pack.name + ' - Yearly'
      } else {
        return plan.info.name === pack.name
      }
    })?.charectiristics,
    info: normalizeInfoOttNames(subscribePlansDescription.find(plan => {
      if (yearly.value && productType.value === 'streaming') {
        return (plan.info.name + ' - Yearly' === pack.name) || (plan.info.name + ' Yearly' === pack.name)
      } else if (productType.value === 'ott' && !yearly.value) {
        return plan.info.name === pack.name + ' - Yearly'
      } else {
        return plan.info.name === pack.name
      }
    })?.info),
  }))
})

const isModerator = computed(() => {
  return store.getters['User/isTeamModerator'];
});

const ownerEmail = computed(() => {
  return store.state.User.guestData.ownersEmail;
})

const shouldShowCompare = computed(() => route.name === 'Payments')

// Hooks
onMounted(async () => {
  loading.value = true
  await loadPackages()
  if (route.path === '/ott-apps') productType.value = 'ott'
  if (subscriptionPackage.value?.category === 'ott') {
    productType.value = 'ott'
    subscriptionPackage.value = _.find(plansData.value, { _id: subscriptionPackage.value._id })
  }
  loading.value = false
})
</script>

<template>
  <div class="container">
    <template v-if="loading">
      <main class="w-full h-[80vh] flex items-center justify-center">
        <Spinner />
      </main>
    </template>
    <template v-else-if="isModerator">
      <div>
        <b-row class="mt-5" align-h="center">
          <b-col md="5">
            <h3 class="text-s-xl text-center text-dark-9 mt-md-5">Moderators don't have access to the Upgrade page.</h3>
            <p class="text-dark-6 text-center text-s-m">Only the team owner and admins can access this page. Contact
              your team owner (<a class="text-reset text-underline" :href="'mailto:' + ownerEmail">{{ ownerEmail }}</a>)
              for more information.</p>
            <p class="text-center">
              <b-button size="md" variant="primary" to="/">
                Go to Livestreams
              </b-button>
            </p>
          </b-col>
        </b-row>
      </div>
    </template>
    <template v-else-if="subscriptionPackage">
      <main class="mt-12">
        <SubscribePlanCart :subscription-package="subscriptionPackage" :is-yearly="yearly" />
      </main>
    </template>
    <template v-else>
      <header v-if="shouldShowCompare" class="mt-12 mb-6">
        <h1 class="text-center font-medium text-xl">Upgrade your subscribtion</h1>
      </header>
      <main>
        <div v-if="shouldShowCompare" class="flex justify-center mb-12">
          <ProductSwitcher v-model:product-type="productType" />
        </div>
        <SubscribePlans :product-type="productType" class="mb-10" :plans-data="plansData" v-model:yearly="yearly" :should-show-compare="shouldShowCompare" />
        <SubscribePlansCompare v-if="shouldShowCompare" :product-type="productType" :plans-data="plansData" v-model:yearly="yearly" />
      </main>
    </template>
  </div>
</template>

<style>
html {
  scroll-behavior: smooth;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
